<template>
  <div class="update-reglement-dynamic">
    <div
      class="block-icon-table-facture"
      @click.prevent.stop="handleUpdate()"
      title="Modifier un Paiement"
    >
      <font-awesome-icon icon="pencil-alt" />
    </div>

    <!-- </template>
      <span>Modifier un Paiement</span>
    </v-tooltip> -->
    <!-- MODIFIER PAIEMENT -->
    <v-dialog
      v-model="dialog"
      max-width="1500px"
      :scrollable="show == true ? false : true"
      hide-overlay
      persistent
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header"> Modifier Paiement</v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="hideModal('updateReglementFacture')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text mt-2">
          <div v-if="show == true" class="loading-custom-reglement-block">
            <Half-circle-spinner
              :animation-duration="1000"
              :size="50"
              :color="'#704ad1'"
              class="loeder"
            />
          </div>
          <v-form v-if="reglementToUpdate" class="mt-2">
            <v-row>
              <v-col col xs="2" sm="2" md="2">
                <v-select
                  placeholder=""
                  :items="computedTypePartenaire"
                  label="Type Partenaire"
                  v-model="reglementToUpdate.type_partenaire"
                  outlined
                  :persistent-placeholder="true"
                  dense
                  :menu-props="{ bottom: true, offsetY: true }"
                  :disabled="true"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                </v-select>
              </v-col>
              <v-col col xs="2.5" sm="2.5" md="2.5">
                <v-text-field
                  label="Partenaire"
                  type="text"
                  placeholder=""
                  :persistent-placeholder="true"
                  outlined
                  dense
                  v-model="reglementToUpdate.client"
                  :disabled="true"
                  color="#704ad1"
                ></v-text-field>
              </v-col>

              <v-col col xs="2" sm="2" md="2">
                <v-select
                  placeholder=""
                  :items="computedTypeReglement"
                  label="Type paiement"
                  v-model="reglementToUpdate.type_id"
                  outlined
                  required
                  :rules="[v => !!v || 'Type paiement obligatoire']"
                  class="msg-error"
                  validate-on-blur
                  :persistent-placeholder="true"
                  dense
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                </v-select>
              </v-col>
              <lcr
                v-if="
                  reglementToUpdate.echeances.length == 0 &&
                    facturesReglement.length > 0
                "
                :montantFix="true"
                :reglement="computedReg"
                :factures="facturesReglement"
                @saveDataForLcr="saveDataForLcr"
                :resetModalLcr="resetModalLcr"
              />
              <v-col
                col
                xs="2.5"
                sm="2.5"
                md="2.5"
                v-if="!computedPaiementNameSelected.includes('LCR')"
              >
                <v-select
                  placeholder=""
                  required
                  :rules="[v => !!v || 'Conditions du paiement obligatoire']"
                  class="msg-error"
                  validate-on-blur
                  :items="computedConditionPaiement"
                  label="Conditions du paiement"
                  v-model="reglementToUpdate.condition_id"
                  outlined
                  :persistent-placeholder="true"
                  dense
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                </v-select>
              </v-col>
              <v-col
                col
                xs="2"
                sm="2"
                md="2"
                v-if="getConditionAmountNonLettre"
              >
                <v-text-field
                  label="Montant non alloué"
                  placeholder=""
                  :persistent-placeholder="true"
                  outlined
                  dense
                  :disabled="true"
                  :value="getAmountNonLettrerReglemnt | numberWithSpaces"
                  color="#704ad1"
                ></v-text-field>
              </v-col>
              <v-col
                col
                :xs="getConditionAmountNonLettre ? '2' : '4'"
                :sm="getConditionAmountNonLettre ? '2' : '4'"
                :md="getConditionAmountNonLettre ? '2' : '4'"
              >
                <v-text-field
                  @input="calculRestePayer"
                  label="Montant à payer"
                  type="number"
                  placeholder=""
                  :persistent-placeholder="true"
                  outlined
                  dense
                  v-model="reglementToUpdate.montant"
                  min="0"
                  step="0.00001"
                  required
                  :rules="[v => !!v || 'Montant à payer est obligatoire']"
                  class="msg-error"
                  validate-on-blur
                  :disabled="true"
                  color="#704ad1"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col col xs="1.25" sm="1.25" md="1.25">
                <v-select
                  placeholder=""
                  :items="computedUnite"
                  label="Unité"
                  v-model="reglementToUpdate.currency"
                  outlined
                  :persistent-placeholder="true"
                  dense
                  :menu-props="{ bottom: true, offsetY: true }"
                  :required="true"
                  :rules="[v => !!v || 'Unité est obligatoire']"
                  class="msg-error"
                  validate-on-blur
                  color="#704ad1"
                  item-color="#704ad1"
                >
                </v-select>
              </v-col>
              <v-col col xs="2.5" sm="2.5" md="2.5">
                <v-text-field
                  label="Réference"
                  type="text"
                  placeholder=""
                  :persistent-placeholder="true"
                  outlined
                  dense
                  v-model="reglementToUpdate.reference"
                  color="#704ad1"
                ></v-text-field>
              </v-col>
              <v-col col xs="2" sm="2" md="2">
                <!-- :opened="true" -->
                <div class="label-for-no-vuetify ">
                  <div class="custom-label">Date paiement</div>
                  <date-picker
                    id="date_paiement"
                    value-type="format"
                    format="DD-MM-YYYY"
                    v-model="reglementToUpdate.payment_date"
                    required
                    type="date"
                    class="inputDatePaiement"
                  ></date-picker>
                </div>
              </v-col>
              <v-col col xs="2" sm="2" md="2">
                <v-text-field
                  label="Reste"
                  placeholder=""
                  :persistent-placeholder="true"
                  outlined
                  dense
                  :disabled="true"
                  :value="reglementToUpdate.balance | numberWithSpaces"
                  color="#704ad1"
                ></v-text-field>
              </v-col>
              <v-col col xs="2" sm="2" md="2">
                <v-text-field
                  label="Solde courant"
                  placeholder=""
                  :persistent-placeholder="true"
                  outlined
                  dense
                  :disabled="true"
                  :value="reglementToUpdate.solde_init | numberWithSpaces"
                  color="#704ad1"
                ></v-text-field>
              </v-col>
              <v-col col xs="2" sm="2" md="2">
                <v-text-field
                  label="Montant Echu"
                  placeholder=""
                  :persistent-placeholder="true"
                  outlined
                  dense
                  :disabled="true"
                  :value="reglementToUpdate.solde_echeance | numberWithSpaces"
                  color="#704ad1"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row
              v-if="
                reglementToUpdate &&
                  reglementToUpdate.canConfirm == true &&
                  reglementToUpdate.type_partenaire == 'Fournisseur'
              "
            >
              <v-col col xs="4" sm="4" md="4"></v-col>
              <v-col col xs="4" sm="1.25" md="1.25">
                <v-select
                  placeholder=""
                  :items="computedListConfirm"
                  label="Confirmation"
                  v-model="reglementToUpdate.confirmation_fournisseur"
                  outlined
                  :persistent-placeholder="true"
                  dense
                  :menu-props="{ bottom: true, offsetY: true }"
                  :required="true"
                  :rules="[v => !!v || 'Confirmation est obligatoire']"
                  class="msg-error"
                  validate-on-blur
                  color="#704ad1"
                  item-color="#704ad1"
                >
                </v-select>
              </v-col>
              <v-col col xs="4" sm="4" md="4"></v-col>
            </v-row>
            <badge
              v-if="getConditionAmountNonLettre"
              class="mt-3 mb-3"
              color="purple"
            />
            <v-row>
              <v-col col xs="12" sm="12" md="12">
                <v-textarea
                  outlined
                  label="Note"
                  v-model="reglementToUpdate.payment_note"
                  :persistent-placeholder="true"
                  clearable
                  rows="3"
                  color="#704ad1"
                ></v-textarea>
              </v-col>
            </v-row>

            <v-tabs
              align-with-title
              background-color="#f1edfb"
              color="#704ad1"
              class="tabs-add-reglement-fac mt-2"
              v-model="indexTab"
            >
              <v-tab active>
                <span class="tab-text-bold">
                  {{ 'Factures régler ' + reglementToUpdate.client }}
                </span>
              </v-tab>
              <v-tab-item>
                <div
                  class="btn-end-postion"
                  v-if="
                    computedCheckRows.length != 0 &&
                      ((reglementToUpdate &&
                        reglementToUpdate.canConfirm == true &&
                        reglementToUpdate.type_partenaire == 'Fournisseur') ||
                        (reglementToUpdate &&
                          reglementToUpdate.type_partenaire == 'Client')) &&
                      reglementToUpdate.comptabilise != 'Comptablisé'
                  "
                >
                  <v-btn
                    class="button-icon-style"
                    dark
                    small
                    color="#704ad1"
                    title="Annuler Paiement"
                    @click="openModalConfirmAnnulation"
                    v-if="reglementToUpdate.comptabilise != 'Comptablisé'"
                  >
                    <font-awesome-icon icon="wrench" class="file-medical" />
                  </v-btn>
                </div>
                <div class="table-reglement-fac-dialog mt-2">
                  <v-data-table
                    v-model="selectedTable"
                    :headers="fields"
                    :items="reglementToUpdate.factures_regles"
                    class="table-facture"
                    hide-default-footer
                    :calculate-widths="false"
                    :fixed-header="true"
                    hide-action
                    disable-sort
                    :items-per-page="reglementToUpdate.factures_regles.length"
                    no-data-text="Il n'y a aucun enregistrement à afficher"
                  >
                    <!-- HEADERS TABLE -->
                    <template v-slot:[`header.check_all`]="{ header }">
                      <th>
                        <span v-if="header.value != 'check_all'">
                          {{ header.label }}</span
                        >
                        <div
                          v-else
                          class="checkbox-class-show mx-auto"
                          :class="{ 'checkbox-class-show-checked': checkAll }"
                          @click="
                            checkAll = !checkAll;
                            selectAll();
                          "
                        >
                          <font-awesome-icon
                            icon="check"
                            v-if="checkAll"
                            class="check"
                          />
                        </div>
                      </th>
                    </template>
                    <!-- BODY TABLE -->
                    <template v-slot:[`item.check_all`]="{ item }">
                      <td class="custom-cell sticky-header-1">
                        <div
                          class="checkbox-class-show mx-auto"
                          :class="{ 'checkbox-class-show-checked': item.check }"
                          @click="item.check = !item.check"
                        >
                          <font-awesome-icon
                            icon="check"
                            v-if="item.check"
                            class="check"
                          />
                        </div>
                      </td>
                    </template>
                    <template v-slot:[`item.num`]="{ item }">
                      <td class="custom-cell">
                        <div class="flex-div">
                          <PopoverReglementFacture
                            v-show="
                              item && item.reglements && item.reglements.length
                            "
                            :factureReglement="item.reglements"
                            :permissionModifier="false"
                            :dataInterface="dataInterface"
                          >
                          </PopoverReglementFacture>
                          {{ item.num }}
                        </div>
                      </td>
                    </template>
                    <template v-slot:[`item.total_ttc`]="{ item }">
                      <td class="custom-cell">
                        {{ item.total_ttc | numberWithSpaces }}
                      </td>
                    </template>
                    <template v-slot:[`item.montant_regle`]="{ item }">
                      <td class="custom-cell">
                        {{ item.montant_regle | numberWithSpaces }}
                      </td>
                    </template>
                    <template v-slot:[`item.montant_a_regle`]="{ item }">
                      <td class="custom-cell">
                        {{ item.montant_a_regle | numberWithSpaces }}
                      </td>
                    </template>
                    <template v-slot:[`item.reste`]="{ item }">
                      <td class="custom-cell">
                        {{ item.reste | numberWithSpaces }}
                      </td>
                    </template>
                    <template v-slot:[`item.vendeur`]="{ item }">
                      <td class="custom-cell">
                        {{ item.vendeur ? item.vendeur : '-' }}
                      </td>
                    </template>
                    <template v-slot:[`item.client`]="{ item }">
                      <td class="custom-cell">
                        {{ item.acheteur ? item.acheteur : '-' }}
                      </td>
                    </template>
                    <template v-slot:[`item.delais_paiement`]="{ item }">
                      <td class="custom-cell">
                        {{
                          formateDateGetters(item.date_echeance)
                            ? formateDateGetters(item.date_echeance)
                            : '-'
                        }}
                      </td>
                    </template>
                  </v-data-table>
                </div>
              </v-tab-item>
              <!--  -->
              <v-tab @click="getfactures">
                <span class="tab-text-bold">
                  {{ 'Factures à régler ' + reglementToUpdate.client }}
                </span>
              </v-tab>
              <v-tab-item>
                <div class="btn-end-postion mt-2">
                  <v-text-field
                    label="Rechercher"
                    placeholder="Rechercher"
                    :persistent-placeholder="true"
                    outlined
                    v-model="search"
                  ></v-text-field>
                </div>
                <div class="table-reglement-fac-dialog">
                  <v-data-table
                    v-model="selectedTable"
                    :headers="fields"
                    :items="facturesReglementComputed"
                    class="table-facture"
                    hide-default-footer
                    :calculate-widths="false"
                    :fixed-header="true"
                    hide-action
                    disable-sort
                    :items-per-page="facturesReglementComputed.length"
                    no-data-text="Il n'y a aucun enregistrement à afficher"
                  >
                    <!-- HEADERS TABLE -->
                    <template v-slot:[`header.check_all`]="{ header }">
                      <th>
                        <span v-if="header.value != 'check_all'">
                          {{ header.label }}</span
                        >
                        <div v-else>
                          <div
                            class="checkbox-class-show mx-auto"
                            :class="{ 'checkbox-class-show-checked': checkAll }"
                            @click="
                              checkAll = !checkAll;
                              handleCheckAll();
                            "
                          >
                            <font-awesome-icon
                              icon="check"
                              v-if="checkAll"
                              class="check"
                            />
                          </div>
                        </div>
                      </th>
                    </template>
                    <!-- BODY TABLE -->
                    <template v-slot:[`item.check_all`]="{ item }">
                      <td class="custom-cell sticky-header-1">
                        <!-- <v-checkbox
                          class="checked-groupe"
                          v-model="item.check"
                          @change="checkFacture(item)"
                          color="#704ad1"
                          :true-value="true"
                          :false-value="false"
                          hide-details
                          :disabled="
                            reglementToUpdate.balance == 0 &&
                              item.check == false
                          "
                        ></v-checkbox> -->
                        <div
                          class="checkbox-class-show mx-auto"
                          :class="{ 'checkbox-class-show-checked': item.check }"
                          @click="
                            item.check = !item.check;
                            checkFacture(item);
                          "
                        >
                          <font-awesome-icon
                            icon="check"
                            v-if="item.check"
                            class="check"
                          />
                        </div>
                      </td>
                    </template>
                    <template v-slot:[`item.num`]="{ item }">
                      <td class="custom-cell">{{ item.num }}</td>
                    </template>
                    <template v-slot:[`item.total_ttc`]="{ item }">
                      <td class="custom-cell">
                        {{ item.total_ttc | numberWithSpaces }}
                      </td>
                    </template>
                    <template v-slot:[`item.montant_regle`]="{ item }">
                      <td class="custom-cell">
                        {{ item.montant_regle | numberWithSpaces }}
                      </td>
                    </template>
                    <template v-slot:[`item.montant_a_regle`]="{ item }">
                      <EditableInput
                        :editable="
                          reglementToUpdate.montant != null &&
                            item.check == true &&
                            reglementToUpdate.balance != 0
                        "
                        champName="montant_a_regle"
                        :item="item"
                        :value="item.montant_a_regle"
                        type="text"
                        editableType="input"
                        :updateFunction="updateMontantRegler"
                        :defaultColor="true"
                      />
                    </template>
                    <template v-slot:[`item.reste`]="{ item }">
                      <td class="custom-cell">
                        {{ item.reste | numberWithSpaces }}
                      </td>
                    </template>
                    <template v-slot:[`item.vendeur`]="{ item }">
                      <td class="custom-cell">
                        {{ item.vendeur ? item.vendeur : '-' }}
                      </td>
                    </template>
                    <template v-slot:[`item.client`]="{ item }">
                      <td class="custom-cell">
                        {{ item.acheteur ? item.acheteur : '-' }}
                      </td>
                    </template>
                    <template v-slot:[`item.delais_paiement`]="{ item }">
                      <td class="custom-cell">
                        {{
                          formateDateGetters(item.date_echeance)
                            ? formateDateGetters(item.date_echeance)
                            : '-'
                        }}
                      </td>
                    </template>
                  </v-data-table>
                </div>
              </v-tab-item>
              <!--  -->
              <v-tab
                v-if="
                  computedPaiementNameSelected.includes('LCR') &&
                    reglementToUpdate.echeances
                "
              >
                <span class="tab-text-bold"> Échéancier de paiement </span>
              </v-tab>
              <v-tab-item
                v-if="
                  computedPaiementNameSelected.includes('LCR') &&
                    reglementToUpdate.echeances
                "
              >
                <div
                  class="btn-end-postion"
                  v-if="computedCheckRowsEcheans.length != 0"
                >
                  <v-btn
                    class="button-icon-style"
                    dark
                    small
                    color="#704ad1"
                    title="Téléchargement PDF d'écheances"
                    @click.prevent.stop="exportMultipleEcheance"
                  >
                    <font-awesome-icon
                      :icon="['fas', 'circle-down']"
                      class="file-medical"
                    />
                  </v-btn>
                </div>
                <div class="table-reglement-fac-dialog mt-2">
                  <v-data-table
                    v-model="selectedTable"
                    :headers="fieldsEcheance"
                    :items="reglementToUpdate.echeances"
                    class="table-facture"
                    hide-default-footer
                    :calculate-widths="false"
                    :fixed-header="true"
                    hide-action
                    disable-sort
                    :items-per-page="reglementToUpdate.echeances.length"
                    no-data-text="Il n'y a aucun enregistrement à afficher"
                  >
                    <!-- HEADERS TABLE -->
                    <template v-slot:[`header.check_all`]="{ header }">
                      <th>
                        <span v-if="header.value != 'check_all'">
                          {{ header.label }}</span
                        >
                        <div v-else>
                          <div
                            class="checkbox-class-show mx-auto"
                            :class="{
                              'checkbox-class-show-checked': checkAllEcheance
                            }"
                            @click="
                              checkAllEcheance = !checkAllEcheance;
                              functionCheckAllEcheance();
                            "
                          >
                            <font-awesome-icon
                              icon="check"
                              v-if="checkAllEcheance"
                              class="check"
                            />
                          </div>
                        </div>
                      </th>
                    </template>
                    <!-- BODY TABLE -->
                    <template v-slot:[`item.check_all`]="{ item }">
                      <td class="custom-cell sticky-header-1">
                        <!-- <v-checkbox
                          class="checked-groupe"
                          v-model="item.check"
                          @change="checkRowsEcheances(item)"
                          color="#704ad1"
                          :true-value="true"
                          :false-value="false"
                          hide-details
                        ></v-checkbox> -->
                        <div
                          class="checkbox-class-show mx-auto"
                          :class="{ 'checkbox-class-show-checked': item.check }"
                          @click="
                            item.check = !item.check;
                            checkRowsEcheances(item);
                          "
                        >
                          <font-awesome-icon
                            icon="check"
                            v-if="item.check"
                            class="check"
                          />
                        </div>
                      </td>
                    </template>
                    <template v-slot:[`item.libelle`]="{ item }">
                      <td class="custom-cell">
                        {{ item.libelle ? item.libelle : '-' }}
                      </td>
                    </template>
                    <template v-slot:[`item.sequence`]="{ item }">
                      <td class="custom-cell">
                        {{ item.sequence ? item.sequence : '-' }}
                        <div class="block-icon-table-facture">
                          <font-awesome-icon
                            :icon="['fas', 'circle-down']"
                            @click.prevent="downloadFacture(item.id)"
                            class="icon-style-color mr-1"
                            title="Télécharger la
                          facture"
                          />
                        </div>
                      </td>
                    </template>
                    <template v-slot:[`item.date_creation`]="{ item }">
                      <td class="custom-cell">
                        {{ item.date_creation ? item.date_creation : '-' }}
                      </td>
                    </template>
                    <template v-slot:[`item.date_echance`]="{ item }">
                      <date-picker
                        v-model="item.date_echeance"
                        value-type="format"
                        type="date"
                        required
                        input-class="custom-date-picker-filter"
                        class="
                                custom-date-picker-calender-filter
                                mt-2
                                mb-2
                              "
                      ></date-picker>
                    </template>
                    <template v-slot:[`item.client`]="{ item }">
                      <td class="custom-cell">
                        {{
                          reglementToUpdate && reglementToUpdate.client
                            ? reglementToUpdate.client
                            : item.client
                        }}
                      </td>
                    </template>
                    <template v-slot:[`item.devise`]="{ item }">
                      <td class="custom-cell">
                        {{ item.devise ? item.devise : '-' }}
                      </td>
                    </template>
                    <template v-slot:[`item.rib`]="{ item }">
                      <td class="custom-cell">
                        {{
                          item.rib && item.rib.rib_bank
                            ? item.rib.rib_bank
                            : '-'
                        }}
                      </td>
                    </template>
                    <template v-slot:[`item.amount`]="{ item, index }">
                      <td class="custom-cell">
                        <input
                          min="0"
                          step="0.00001"
                          :max="reglementToUpdate.montant"
                          type="number"
                          autocomplete="off"
                          v-model="item.amount"
                          @input="changeMontantEcheance(index)"
                        />
                      </td>
                    </template>
                    <template v-slot:[`item.date_envoi`]="{ item }">
                      <date-picker
                        v-model="item.date_envoi"
                        value-type="format"
                        type="date"
                        required
                        input-class="custom-date-picker-filter"
                        class="
                                custom-date-picker-calender-filter
                                mt-2
                                mb-2
                              "
                      ></date-picker>
                    </template>
                    <template v-slot:[`item.date_signature`]="{ item }">
                      <date-picker
                        v-model="item.date_signature"
                        value-type="format"
                        type="date"
                        required
                        input-class="custom-date-picker-filter"
                        class="
                                custom-date-picker-calender-filter
                                mt-2
                                mb-2
                              "
                      ></date-picker>
                    </template>
                    <template v-slot:[`item.date_valeur`]="{ item }">
                      <date-picker
                        v-model="item.date_valeur"
                        value-type="format"
                        type="date"
                        required
                        input-class="custom-date-picker-filter"
                        class="
                                custom-date-picker-calender-filter
                                mt-2
                                mb-2
                              "
                      ></date-picker>
                    </template>
                    <template v-slot:[`body.append`]="{}">
                      <tr
                        v-if="
                          reglementToUpdate &&
                            reglementToUpdate.echeances.length > 0
                        "
                      >
                        <td class="custom-cell">-</td>
                        <td class="custom-cell">-</td>
                        <td class="custom-cell">-</td>
                        <td class="custom-cell">-</td>
                        <td class="custom-cell">-</td>
                        <td class="custom-cell">-</td>
                        <td class="custom-cell">-</td>
                        <td class="custom-cell">-</td>
                        <td class="custom-cell"
                          >{{ reglementToUpdate.totalEcheance }}
                        </td>
                        <td class="custom-cell">-</td>
                        <td class="custom-cell">-</td>
                        <td class="custom-cell">-</td>
                        <td class="custom-cell">-</td>
                      </tr>
                      <!-- <tr v-if="reglementToUpdate.echeances.length == 0">
                        <td :colspan="headers.length">
                          Il n'y a aucune tranche à afficher
                        </td>
                      </tr> -->
                    </template>
                  </v-data-table>
                </div>
              </v-tab-item>
              <!--  -->
              <v-tab
                ><span class="tab-text-bold">Fichiers jointes</span>
              </v-tab>
              <v-tab-item>
                <PieceJointe
                  :permission="true"
                  :item="reglementToUpdate.files"
                  :reglement="itemProps"
                  :show="true"
                  typeVoir="updateFiles"
                  @showFiles="showFiles"
                  @showFilesRecent="showFilesRecent"
                ></PieceJointe>
              </v-tab-item>
            </v-tabs>
          </v-form>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div v-if="error" class="message-error-modal">
            <ul v-if="Array.isArray(error)" class="mb-0">
              <li v-for="(e, index) in error" :key="index">
                {{ e }}
              </li>
            </ul>
            <div v-else>{{ error }}</div>
          </div>
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            text
            @click="handleSubmitUpdateReglement"
            :disabled="
              reglementToUpdate &&
                reglementToUpdate.comptabilise == 'Comptablisé'
            "
            :class="{ loader: loading }"
          >
            Valider
          </v-btn>
          <v-btn
            v-if="
              (reglementToUpdate &&
                reglementToUpdate.canConfirm == true &&
                reglementToUpdate.type_partenaire == 'Fournisseur') ||
                (reglementToUpdate &&
                  reglementToUpdate.type_partenaire == 'Client')
            "
            text
            @click.prevent.stop="handleModalAnnulerTotal"
          >
            <span>
              Annuler le Paiement
              <div v-if="loadingAnnuler" class="loading ml-2">
                <div class="spinner-border" role="status"></div>
              </div>
            </span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ANNULATION PAIEMENT  -->
    <v-dialog
      v-model="annulationTotal"
      max-width="820px"
      scrollable
      hide-overlay
      persistent
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Annulation Totale de Paiement</v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click.prevent="annulerConfirmationTotal"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          Vous devez confirmer l'annulation totale de ce Paiement ?
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div v-if="error" class="message-error-modal">
            <ul v-if="Array.isArray(error)" class="mb-0">
              <li v-for="(e, index) in error" :key="index">
                {{ e }}
              </li>
            </ul>
            <div v-else>{{ error }}</div>
          </div>
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            text
            @click="AnnulerReglementTotal"
            :class="{ loader: loadingValideAnnuler }"
          >
            Valider
          </v-btn>
          <v-btn
            text
            @click="annulerConfirmationTotal"
            :class="{ loader: loadingValideAnnuler }"
            >Annuler</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- CONFIRMATION ANNULER PAIEMENT-->
    <v-dialog
      v-model="confirmAnnulation"
      max-width="820px"
      scrollable
      hide-overlay
      persistent
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header"
            >Annuler le(s) Paiement(s) sélectionnés</v-label
          >
          <v-btn
            class="btn-close-header"
            icon
            @click.prevent="annulerConfirmation"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          Vous devez confirmer l'annulation de ce(s) Paiement(s) ?
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div v-if="error" class="message-error-modal">
            <ul v-if="Array.isArray(error)" class="mb-0">
              <li v-for="(e, index) in error" :key="index">
                {{ e }}
              </li>
            </ul>
            <div v-else>{{ error }}</div>
          </div>
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            text
            @click="submitAnnulationReglement"
            :class="{ loader: annulerReglement }"
            :loading="annulerReglement"
          >
            Valider
          </v-btn>
          <v-btn
            text
            @click="annulerConfirmation"
            :class="{ loader: loadingAnnuler }"
            >Annuler</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Reglements from '@/models/Reglements';

import { mapActions, mapGetters } from 'vuex';
export default {
  props: {
    item: { required: true },
    dataInterface: { required: true },
    grouped: { default: true }
  },
  data() {
    return {
      recentJoint: [],
      annulationTotal: false,
      confirmAnnulation: false,
      dialog: false,
      itemReglement: null,
      reglementToUpdate: null,
      show: false,
      showEcheance: false,
      reglement: null,
      search: null,
      files: [],
      error: [],
      loading: false,
      loadingAnnuler: false,
      checkAll: false,
      resetModalLcr: false,
      dataLcr: null,
      checkAllEcheance: false,
      loadingValideAnnuler: false,
      annulerReglement: false,
      indexTab: 'tab-0',
      fields: [
        {
          value: 'check_all',
          text: '',
          width: '40px !important',
          class: 'sticky-header-1'
        },
        {
          value: 'num',
          text: 'Numéro'
        },
        {
          value: 'total_ttc',
          text: 'Montant Total'
        },
        {
          value: 'reste',
          text: 'Reste'
        },
        {
          value: 'montant_regle',
          text: 'Montant Réglé'
        },
        {
          value: 'montant_a_regle',
          text: 'Montant à Régler'
        },
        {
          value: 'vendeur',
          text: 'Société'
        },
        {
          value: 'client',
          text: 'Client'
        },
        {
          value: 'delais_paiement',
          text: 'Délais du paiement'
        }
      ],
      fieldsEcheance: [
        {
          value: 'check_all',
          text: '',
          width: '40px !important',
          class: 'sticky-header-1'
        },
        {
          value: 'libelle',
          text: 'Libellé'
        },
        {
          value: 'sequence',
          text: 'Séquence'
        },
        {
          value: 'date_creation',
          text: 'Date de création'
        },
        { value: 'date_echance', text: "Date d'échéance" },
        {
          value: 'client',
          text: 'Client/Fournisseur'
        },
        {
          value: 'devise',
          text: 'Devise'
        },
        {
          value: 'rib',
          text: 'Banque du tiré'
        },
        {
          value: 'amount',
          text: 'Montant'
        },
        {
          value: 'date_envoi',
          text: "Date d'envoi"
        },
        {
          value: 'date_signature',
          text: 'Date de signature'
        },
        {
          value: 'date_valeur',
          text: 'Date valeur'
        }
      ],
      selectedTable: []
    };
  },
  computed: {
    ...mapGetters([
      'soldeClient',
      'soldeEcheance',
      'TypeReglement',
      'facturesReglement',
      'ConditionPaiement',
      'formateDateGetters',
      'getListProjectsFraisDync',
      'getAmountNonLettrerReglemnt',
      'factureScis',
      'factureMensuels',
      'factureMaster2',
      'getFacturesLibresTh',
      'getConditionAmountNonLettre'
      //   'clientsReglement'
    ]),
    itemProps() {
      return this.item;
    },
    computedListConfirm() {
      let confirmList = [
        {
          text: 'Oui',
          value: true
        },
        {
          text: 'Non',
          value: false
        }
      ];
      return confirmList;
    },
    computedCheckRowsEcheans() {
      let tableCheck = [];
      this.reglementToUpdate.echeances?.map(reglement => {
        if (reglement.check == true) {
          tableCheck.push(reglement);
        }
      });
      return tableCheck;
    },
    computedConditionPaiement() {
      return this.ConditionPaiement.map(item => {
        return {
          text: item.name,
          value: item.id
        };
      });
    },
    computedTypePartenaire() {
      let typePartenaire = [
        { text: 'Client', value: 'Client' },
        { text: 'Fournisseur', value: 'Fournisseur' }
      ];
      return typePartenaire;
    },
    computedTypeReglement() {
      return this.TypeReglement.map(item => {
        return {
          text: item.name,
          value: item.id
        };
      });
    },
    computedPaiementNameSelected() {
      if (this.reglementToUpdate && this.reglementToUpdate.type_id != null) {
        let fund = this.TypeReglement.filter(
          i => i.id == this.reglementToUpdate.type_id
        );
        return fund && fund.length ? fund[0].name : null;
      }
      return this.reglementToUpdate.type_id + '';
    },
    computedUnite() {
      let unite = [
        { text: 'EUR', value: 'EUR' },
        { text: 'USD', value: 'USD' }
      ];
      return unite;
    },
    computedCheckRows() {
      let tableCheck = [];
      this.reglementToUpdate.factures_regles?.map(reglement => {
        if (reglement.check == true && reglement.reglements.length) {
          tableCheck.push(reglement);
        }
      });
      return tableCheck;
    },
    facturesReglementComputed() {
      if (this.search != null && this.search != '') {
        return this.facturesReglement.filter(item =>
          item.num.toLowerCase().includes(this.search.toLowerCase())
        );
      }
      return this.facturesReglement;
    }
  },
  methods: {
    ...mapActions([
      'getSoldeClient',
      'getSoldeEcheance',
      'fetachDataWithReglementId',
      'getAllTypesReglement',
      'getAllConditionsPaiement',
      'getFactureByFilialeReglement',
      'updateReglement',
      'annulationPayment',
      'annulationPaymentFacture',
      'updateFileReglement',
      'AttachFiles',
      'updatePaymentEcheance',
      'exportEcheanceReglement',
      'getAmountNonLettrer'
    ]),
    showFiles(file) {
      this.files = file;
    },
    showFilesRecent(files) {
      this.reglementToUpdate.files = [...files];
    },
    openModalConfirmAnnulation() {
      this.confirmAnnulation = true;
    },
    updateDataInHomeTable(responseUpdate) {
      let dataToUseInUpdate =
        this.dataInterface == 'frais'
          ? this.getListProjectsFraisDync
          : this.dataInterface == 'sci'
          ? this.factureScis
          : this.dataInterface == 'mensual'
          ? this.factureMensuels
          : this.dataInterface == 'master'
          ? this.factureMaster2
          : this.dataInterface == 'libre'
          ? this.getFacturesLibresTh
          : [];

      let FolderName = this.dataInterface == 'frais' ? 'projects' : 'factures';
      responseUpdate.forEach(reg => {
        let indexFacture = -1;
        if (this.grouped) {
          let indexFiliale = dataToUseInUpdate.findIndex(i =>
            i[FolderName].some(e => e.id == reg.id)
          );
          if (indexFiliale != -1) {
            indexFacture = dataToUseInUpdate[indexFiliale][
              FolderName
            ].findIndex(i => i.id == reg.id);
            dataToUseInUpdate[indexFiliale][FolderName][
              indexFacture
            ].updateDataReglement(reg);
          }
        } else {
          indexFacture = dataToUseInUpdate.findIndex(i => i.id == reg.id);
          if (indexFacture != -1) {
            dataToUseInUpdate[indexFacture].updateDataReglement(reg);
          }
        }
      });
    },
    annulerConfirmationTotal() {
      this.annulationTotal = false;
    },
    annulerConfirmation() {
      this.confirmAnnulation = false;
      this.reglementToUpdate.factures_regles.map(reglement => {
        if (reglement.check == true) {
          reglement.check = false;
        }
      });
    },
    async exportMultipleEcheance() {
      let bodyFormData = new FormData();
      let checked = [];
      checked = this.reglementToUpdate.echeances.filter(
        item => item.check == true
      );
      checked.forEach((element, index) => {
        bodyFormData.append('ids[' + index + ']', element.id);
      });
      const response = await this.exportEcheanceReglement(bodyFormData);
      if (response) {
        this.reglementToUpdate.echeances.forEach((element, index) => {
          element.check = false;
        });
        this.checkAllEcheance = false;
      }
    },
    changeMontantEcheance(index) {
      let sum = 0;
      let index_update =
        index == this.reglementToUpdate.echeances.length - 1
          ? 0
          : this.reglementToUpdate.echeances.length - 1;

      let index_start =
        index == this.reglementToUpdate.echeances.length - 1 ? 1 : 0;
      let index_end =
        index == this.reglementToUpdate.echeances.length - 1
          ? this.reglementToUpdate.echeances.length
          : this.reglementToUpdate.echeances.length - 1;
      this.reglementToUpdate.echeances.forEach((element, index) => {
        if (index >= index_start && index < index_end) {
          sum = parseFloat(sum) + parseFloat(element.amount);
        }
      });
      this.reglementToUpdate.echeances[index_update].amount = (
        this.reglementToUpdate.totalEcheance - sum
      ).toFixed(2);
    },
    async downloadFacture(id) {
      let bodyFormData = new FormData();
      bodyFormData.append('ids[0]', id);
      await this.exportEcheanceReglement(bodyFormData);
    },
    checkRowsEcheances(item) {
      let checked = [];
      if (item.check == false && this.checkAllEcheance == true) {
        this.checkAllEcheance = false;
      }
      this.reglementToUpdate?.echeances?.map(facture => {
        if (facture.check == true) {
          checked.push(facture);
        }
      });
      if (checked.length == this.reglementToUpdate.echeances.length) {
        this.checkAllEcheance = true;
      }
    },
    async handleSubmitFiles() {
      this.error = [];
      if (this.files.length != 0 || this.recentJoint.length != 0) {
        this.loading = true;
        if (this.recentJoint.length != 0) {
          let bodyFormData = new FormData();
          for (let i = 0; i < this.recentJoint?.length; i++) {
            bodyFormData.append(
              'files[' + i + '][file_id]',
              this.recentJoint[i].id
            );
            bodyFormData.append(
              'files[' + i + '][description]',
              this.recentJoint[i].description
            );
          }

          const response = await this.updateFileReglement({
            bodyFormData: bodyFormData,
            reglement: this.reglementToUpdate
          });
          if (response.success) {
            if (this.files.length != 0) {
              let bodyFormData = new FormData();
              bodyFormData.append('payment_id', this.reglementToUpdate.id);
              for (let i = 0; i < this.files?.length; i++) {
                bodyFormData.append('files[' + i + '][file]', this.files[i]);
                if (this.files[i]['description'] != undefined) {
                  bodyFormData.append(
                    'files_descriptions[' + i + ']',
                    this.files[i]['description']
                  );
                }
              }
              const response = await this.AttachFiles({
                bodyFormData: bodyFormData,
                payment_id: this.reglementToUpdate.id
              });
              if (response.success) {
                this.loading = false;
                this.files = [];
                this.hideModal('ModalPieceJoint');
                return true;
              }
            } else {
              this.files = [];
              this.loading = false;
              this.hideModal('ModalPieceJoint');
              return true;
            }
          }
        } else {
          if (this.files.length != 0) {
            let bodyFormData = new FormData();
            bodyFormData.append('payment_id', this.reglementToUpdate.id);
            for (let i = 0; i < this.files?.length; i++) {
              bodyFormData.append('files[' + i + '][file]', this.files[i]);
              if (this.files[i]['description'] != undefined) {
                bodyFormData.append(
                  'files_descriptions[' + i + ']',
                  this.files[i]['description']
                );
              }
            }
            const response = await this.AttachFiles({
              bodyFormData: bodyFormData,
              payment_id: this.reglementToUpdate.id
            });

            if (response.success) {
              this.loading = false;
              this.hideModal('ModalPieceJoint');
              return true;
            }
          }
        }
      }
    },
    async submitAnnulationReglement() {
      this.annulerReglement = true;
      let bodyFormData = new FormData();
      bodyFormData.append(
        'source',
        this.dataInterface == 'frais' ? 'support' : 'libre'
      );
      let indexAnnulationReglement = 0;
      for (let j = 0; j < this.reglementToUpdate.factures_regles.length; j++) {
        if (this.reglementToUpdate.factures_regles[j].check == true) {
          for (
            let i = 0;
            i < this.reglementToUpdate.factures_regles[j].reglements.length;
            i++
          ) {
            bodyFormData.append(
              'obj[' + indexAnnulationReglement + '][facture_id]',
              this.reglementToUpdate.factures_regles[j].reglements[i].facture_id
            );
            bodyFormData.append(
              'obj[' + indexAnnulationReglement + '][payment_facture_id]',
              this.reglementToUpdate.factures_regles[j].reglements[i].id
            );
            indexAnnulationReglement = indexAnnulationReglement + 1;
          }
        }
      }
      const response = await this.annulationPaymentFacture({
        bodyFormData: bodyFormData,
        oldReglement: this.reglementToUpdate,
        source: this.dataInterface == 'frais' ? 'support' : 'libre'
      });

      if (response.succes) {
        //mettre a jour done
        this.updateDataInHomeTable(response.data);
        this.reglementToUpdate.factures_regles = this.reglementToUpdate.factures_regles.filter(
          i => i.check == false
        );
        this.annulerReglement = false;
        this.confirmAnnulation = false;
      }
    },
    async AnnulerReglementTotal() {
      this.loadingValideAnnuler = true;
      const response = await this.annulationPayment({
        id: this.reglementToUpdate.id,
        oldReglement: this.reglementToUpdate,
        source: this.dataInterface == 'frais' ? 'support' : 'libre'
      });

      if (response) {
        this.updateDataInHomeTable(response.data);
        this.reglementToUpdate.factures_regles = [];
        this.loadingValideAnnuler = false;
        this.annulationTotal = false;
        this.hideModal('updateReglementFacture');
      }
    },
    handleModalAnnulerTotal() {
      this.annulationTotal = true;
    },
    functionCheckAllEcheance() {
      this.reglementToUpdate.echeances.map(item => {
        item.check = this.checkAllEcheance;
      });
    },
    async handleSubmitUpdateReglement() {
      if (this.reglementToUpdate.montant == 0) {
        this.error = 'Il faut avoir un montant à payer supèrieur à 0 ';
      } else {
        this.loading = true;
        this.reglementToUpdate.echeances.forEach(async (element, index) => {
          let bodyFormDataEcheance = new FormData();
          bodyFormDataEcheance.append('id', element.id);
          bodyFormDataEcheance.append('libelle', element.libelle);
          bodyFormDataEcheance.append('client_id', element.client_id);
          bodyFormDataEcheance.append('date_creation', element.date_creation);
          bodyFormDataEcheance.append('date_echeance', element.date_echeance);
          bodyFormDataEcheance.append(
            'beneficiaire_id',
            element.beneficiaire_id
          );
          bodyFormDataEcheance.append('valeur_en', element.valeur_en);
          bodyFormDataEcheance.append('date_envoi', element.date_envoi);
          bodyFormDataEcheance.append('date_signature', element.date_signature);
          bodyFormDataEcheance.append('date_valeur', element.date_valeur);
          bodyFormDataEcheance.append('devise', element.devise);
          bodyFormDataEcheance.append('amount', element.amount);
          bodyFormDataEcheance.append('rib_id', element.rib.id);
          bodyFormDataEcheance.append('sequence', element.sequence);
          bodyFormDataEcheance.append('etat', element.etat);
          bodyFormDataEcheance.append('ordre_de', element.ordre_de);
          const response = await this.updatePaymentEcheance(
            bodyFormDataEcheance
          );
          if (response.succes) {
            this.reglementToUpdate.echeances[index].amount =
              response.data.amount;
            this.reglementToUpdate.echeances[index].date_creation =
              response.data.date_creation;
            this.reglementToUpdate.echeances[index].date_echeance =
              response.data.date_echeance;
            this.reglementToUpdate.echeances[index].date_signature =
              response.data.date_signature;
            this.reglementToUpdate.echeances[index].date_valeur =
              response.data.date_valeur;
            this.reglementToUpdate.echeances[index].date_envoi =
              response.data.date_envoi;
          }
        });
        let bodyFormData = new FormData();
        bodyFormData.append('payment_id', this.reglementToUpdate.id);
        bodyFormData.append('balance', this.reglementToUpdate.balance);
        bodyFormData.append('amount', this.reglementToUpdate.montant);
        bodyFormData.append('currency', this.reglementToUpdate.currency);
        bodyFormData.append('canConfirm', this.reglementToUpdate.canConfirm);
        bodyFormData.append(
          'confirmation_fournisseur',
          this.reglementToUpdate.confirmation_fournisseur
        );
        bodyFormData.append(
          'payment_date',
          this.reglementToUpdate.payment_date
        );
        bodyFormData.append('type_id', this.reglementToUpdate.type_id);
        if (!this.computedPaiementNameSelected.includes('LCR')) {
          bodyFormData.append(
            'payment_condition_id',
            this.reglementToUpdate.condition_id
          );
        }
        bodyFormData.append('filiale_id', this.reglementToUpdate.client_id);
        bodyFormData.append(
          'payment_note',
          this.reglementToUpdate.payment_note
        );
        bodyFormData.append('reference', this.reglementToUpdate.reference);
        bodyFormData.append(
          'type_partenaire',
          this.reglementToUpdate.type_partenaire
        );
        let reg = this.facturesReglement.filter(i => i.check == true);
        for (let i = 0; i < reg.length; i++) {
          bodyFormData.append('factures[' + i + '][id]', reg[i].id);
          bodyFormData.append(
            'factures[' + i + '][montant_regle]',
            reg[i].montant_a_regle
          );
          bodyFormData.append(
            'factures[' + i + '][total_ttc]',
            reg[i].total_ttc
          );
        }
        this.recentJoint = [];
        this.recentJoint = this.reglementToUpdate.files;
        const responseUpdate = await this.updateReglement({
          bodyFormData: bodyFormData,
          oldReglement: this.reglementToUpdate
        });

        if (responseUpdate.succes) {
          this.updateDataInHomeTable(responseUpdate.data.factures_regles);
          if (
            this.dataLcr &&
            this.dataLcr?.lcr &&
            this.dataLcr?.lcr?.length != 0 &&
            this.reglementToUpdate.type_id != null &&
            this.computedPaiementNameSelectedLcr.includes('LCR') &&
            this.reglementToUpdate.echeances.length == 0
          ) {
            let bodyFormDataEch = new FormData();
            this.dataLcr?.lcr?.forEach((element, i) => {
              bodyFormDataEch.append(
                'echeances[' + i + '][libelle]',
                element.libelle
              );
              bodyFormDataEch.append(
                'echeances[' + i + '][client_id]',
                this.dataLcr.client_id
              );
              bodyFormDataEch.append(
                'echeances[' + i + '][date_echeance]',
                element.date
              );
              bodyFormDataEch.append(
                'echeances[' + i + '][beneficiaire_id]',
                this.dataLcr.beneficiaire_id
              );
              bodyFormDataEch.append(
                'echeances[' + i + '][valeur_en]',
                this.dataLcr.valeur_en
              );
              bodyFormDataEch.append('echeances[' + i + '][date_envoi]', null);
              bodyFormDataEch.append(
                'echeances[' + i + '][date_signature]',
                null
              );
              bodyFormDataEch.append('echeances[' + i + '][date_valeur]', null);
              bodyFormDataEch.append(
                'echeances[' + i + '][devise]',
                this.dataLcr.devise
              );
              bodyFormDataEch.append(
                'echeances[' + i + '][amount]',
                element.value
              );
              bodyFormDataEch.append(
                'echeances[' + i + '][rib_id]',
                this.dataLcr.rib.id
              );
            });
            const resEch = await this.createReglementLcrFactureEcheances({
              response: this.reglementToUpdate,
              data: bodyFormDataEch
            });
            if (resEch) {
              let total = 0;
              this.reglementToUpdate.echeances.map(item => {
                total = total + item.amount;
              });
              this.reglementToUpdate.totalEcheance = total.toFixed(2);
            }
          }
          const responseFile = await this.handleSubmitFiles();
          if (responseFile) {
            this.files = [];
            this.recentJoint = [];
          }
          this.loading = false;
          this.show = true;
          this.getAmountNonLettrer({
            filiale_id: this.reglementToUpdate.client_id,
            type_partenaire: this.reglementToUpdate.type_partenaire
          });
          const response = await this.getFactureByFilialeReglement({
            filiale_id: this.reglementToUpdate.client_id,
            type_partenaire: this.reglementToUpdate.type_partenaire
          });

          if (response) {
            ///for reglement
            // this.clientsReglement.map(item => {
            //   if (item.id == this.reglementToUpdate.client_id) {
            //     this.vendeur = item.name;
            //   }
            // });
            const response = await this.getSoldeClient({
              id: this.reglementToUpdate.client_id,
              type_partenaire: this.reglementToUpdate.type_partenaire
            });
            if (response) {
              this.reglementToUpdate.solde = this.soldeClient;
              this.reglementToUpdate.solde_init = this.soldeClient;
              if (response) {
                this.reglementToUpdate.solde_echeance = this.soldeEcheance;
                this.show = false;
              }
            }
          }
          this.hideModal('updateReglementFacture');
        } else {
          this.loading = false;
        }
      }
    },
    checkFacture(item) {
      let dataCheck = this.facturesReglement.filter(
        i => i.check == true && i.id != item.id
      );
      if (
        dataCheck.length == 0 ||
        (dataCheck.length > 0 && dataCheck[0].vendeur_id == item.vendeur_id)
      ) {
        this.reglementToUpdate.balance = parseFloat(
          this.reglementToUpdate.balance
        );
        this.reglementToUpdate.solde = parseFloat(this.reglementToUpdate.solde);
        if (this.reglementToUpdate.balance > 0) {
          if (item.check == true) {
            if (this.reglementToUpdate.balance > item.resteInitial) {
              this.reglementToUpdate.balance =
                this.reglementToUpdate.balance - parseFloat(item.resteInitial);
              this.reglementToUpdate.balance = this.reglementToUpdate.balance.toFixed(
                2
              );
              item.reste = 0;
              item.montant_a_regle =
                parseFloat(item.montant_a_regle) +
                parseFloat(item.resteInitial);
              this.reglementToUpdate.solde =
                this.reglementToUpdate.solde - parseFloat(item.montant_a_regle);
              this.reglementToUpdate.solde = this.reglementToUpdate.solde.toFixed(
                2
              );
              this.reglementToUpdate.solde = Math.abs(
                this.reglementToUpdate.solde
              );
              if (this.computedCheckAll == true) {
                this.checkAll = true;
              } else {
                this.checkAll = false;
              }
            } else {
              item.montant_a_regle = this.reglementToUpdate.balance.toFixed(2);
              item.reste =
                parseFloat(item.resteInitial) - this.reglementToUpdate.balance;
              item.reste = item.reste.toFixed(2);
              this.reglementToUpdate.balance = 0;
              this.reglementToUpdate.solde =
                this.reglementToUpdate.solde - parseFloat(item.montant_a_regle);
              this.reglementToUpdate.solde = this.reglementToUpdate.solde.toFixed(
                2
              );
              this.reglementToUpdate.solde = Math.abs(
                this.reglementToUpdate.solde
              );
              if (this.computedCheckAll == true) {
                this.checkAll = true;
              } else {
                this.checkAll = false;
              }
            }
          } else {
            this.reglementToUpdate.balance =
              this.reglementToUpdate.balance + parseFloat(item.montant_a_regle);
            this.reglementToUpdate.balance = this.reglementToUpdate.balance.toFixed(
              2
            );
            this.reglementToUpdate.solde =
              this.reglementToUpdate.solde + parseFloat(item.montant_a_regle);
            this.reglementToUpdate.solde = this.reglementToUpdate.solde.toFixed(
              2
            );
            item.reste = parseFloat(item.resteInitial);
            item.montant_a_regle = parseFloat(item.montant_a_regle_initial);
            if (this.computedCheckAll == true) {
              this.checkAll = true;
            } else {
              this.checkAll = false;
            }
          }
        } else {
          if (item.check == false) {
            this.reglementToUpdate.balance =
              this.reglementToUpdate.balance + parseFloat(item.montant_a_regle);
            this.reglementToUpdate.balance = this.reglementToUpdate.balance.toFixed(
              2
            );
            this.reglementToUpdate.solde =
              this.reglementToUpdate.solde + parseFloat(item.montant_a_regle);
            this.reglementToUpdate.solde = this.reglementToUpdate.solde.toFixed(
              2
            );
            item.reste = parseFloat(item.resteInitial);
            item.montant_a_regle = parseFloat(item.montant_a_regle_initial);
            this.checkAll = false;
          } else {
            item.check = false;
            this.checkAll = false;
          }
        }
      } else {
        item.check = !item.check;
      }
    },
    saveDataForLcr(data) {
      this.dataLcr = data;
    },
    updateMontantRegler(payload) {
      this.reglementToUpdate.balance = parseFloat(
        this.reglementToUpdate.balance
      );
      this.reglementToUpdate.solde = parseFloat(this.reglementToUpdate.solde);

      if (
        parseFloat(payload.row.montant_a_regle) +
          parseFloat(this.reglementToUpdate.balance) >
        parseFloat(payload.value)
      ) {
        payload.row.montant_a_regle = parseFloat(payload.value).toFixed(2);
        payload.row.reste = parseFloat(
          payload.row.resteInitial - parseFloat(payload.value).toFixed(2)
        );
        payload.row.reste = payload.row.reste.toFixed(2);
        payload.row.reste = parseFloat(payload.row.reste);
        this.reglementToUpdate.balance =
          this.reglementToUpdate.balance + parseFloat(payload.row.resteInitial);
        this.reglementToUpdate.balance =
          this.reglementToUpdate.balance - parseFloat(payload.value);
        this.reglementToUpdate.balance = this.reglementToUpdate.balance.toFixed(
          2
        );
        this.reglementToUpdate.solde =
          this.reglementToUpdate.solde + parseFloat(payload.row.resteInitial);
        this.reglementToUpdate.solde =
          this.reglementToUpdate.solde - parseFloat(payload.value);
        this.reglementToUpdate.solde = this.reglementToUpdate.solde.toFixed(2);
      }
    },
    computedReg() {
      return {
        filiale_id: this.reglementToUpdate.client_id,
        type_reglement: this.reglementToUpdate.type_id,
        unite: this.reglementToUpdate.currency,
        montant_a_payer: this.reglementToUpdate.montant
      };
    },
    handleCheckAll() {
      if (this.checkAll == true) {
        let table = this.facturesReglementComputed.filter(
          item => item.check == false
        );
        table.map(item => {
          if (this.reglementToUpdate.balance > 0) {
            item.check = true;
            this.checkFacture(item);
          }
        });
      } else {
        let table = this.facturesReglementComputed.filter(
          item => item.check == true
        );
        table.map(item => {
          if (item.check == true) {
            item.check = false;
            this.checkFacture(item);
          }
        });
      }
    },
    selectAll() {
      this.reglementToUpdate.factures_regles.map(
        i => (i.check = this.checkAll)
      );
    },
    async handleUpdate() {
      // this.$emit('openModal');
      this.dialog = true;
      this.item.payment_id = this.item.payment_id
        ? this.item.payment_id
        : this.item.id;
      this.show = true;
      const res = await this.fetachDataWithReglementId(this.item);

      if (res.success) {
        this.itemReglement = Reglements.create(res.data);
        let total = 0;
        this.reglementToUpdate = this.itemReglement;
        this.reglementToUpdate.echeances.map(item => {
          total = total + item.amount;
        });
        if (this.reglementToUpdate && this.reglementToUpdate.type_id != null) {
          this.computedTypeReglement.map(item => {
            if (
              item.value == this.reglementToUpdate.type_id &&
              item.text == 'LCR Lettre de change Relevé'
            ) {
              this.showEcheance = true;
            }
          });
        }
        this.reglementToUpdate.totalEcheance = total.toFixed(2);
        this.reglement = {
          filiale_id: this.itemReglement.client_id,
          type_reglement: this.itemReglement.type_id,
          unite: this.itemReglement.currency,
          montant_a_payer: this.itemReglement.montant
        };
        this.getAmountNonLettrer({
          filiale_id: this.reglementToUpdate.client_id,
          type_partenaire: this.reglementToUpdate.type_partenaire
        });
        const response = await this.getSoldeClient({
          id: this.reglementToUpdate.client_id,
          type_partenaire: this.reglementToUpdate.type_partenaire
        });
        if (response) {
          this.reglementToUpdate.solde = this.soldeClient;
          this.reglementToUpdate.solde_init = this.soldeClient;
          const response = await this.getSoldeEcheance({
            id: this.reglementToUpdate.client_id,
            type_partenaire: this.reglementToUpdate.type_partenaire
          });
          if (response) {
            this.reglementToUpdate.solde_echeance = this.soldeEcheance;
          }
        }
      }

      this.show = false;
    },
    calculRestePayer() {
      if (
        parseFloat(this.reglementToUpdate.montant) >
        this.reglementToUpdate.montant_initial
      ) {
        let montant =
          parseFloat(this.reglementToUpdate.montant) -
          this.reglementToUpdate.montant_initial;
        this.reglementToUpdate.balance =
          parseFloat(this.reglementToUpdate.balance) + montant;
        let TableF = this.facturesReglement?.filter(
          item => item.check == false
        );
        if (TableF.length != this.facturesReglement.length) {
          this.getFacturesFiliales();
        }
      }
    },
    hideModal(ref) {
      this.$refs[ref]?.hide();
      if (ref == 'updateReglementFacture') {
        // this.$emit('closeModal');
        this.dialog = false;
        this.indexTab = 'tab-0';
      }
      this.resetModal();
    },
    resetModal() {
      this.show = false;
    },
    async getfactures() {
      this.show = true;
      const response = await this.getFactureByFilialeReglement({
        filiale_id: this.reglementToUpdate.client_id,
        type_partenaire: this.reglementToUpdate.type_partenaire
      });
      if (response) {
        this.reglementToUpdate.balance = this.reglementToUpdate.balance_initial;
        this.show = false;
      }
    }
  },
  filters: {
    numberWithSpaces: x => {
      // return new Intl.NumberFormat().format(x);
      if (x != null && x != undefined) {
        if (Math.sign(x) === -1) {
          var y = Math.abs(x);
          var parts = y.toString().split('.');
          parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
          return '-' + parts.join('.');
        } else {
          var parts = x.toString().split('.');
          parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
          return parts.join('.');
        }
      }
    }
  },
  components: {
    lcr: () => import('@/views/component/reglement/lcr.vue'),
    EditableInput: () => import('@/views/component/EditableInput.vue'),
    badge: () => import('@/views/component/badge.vue'),
    PieceJointe: () => import('@/views/component/Facture/PieceJointe.vue'),
    PopoverReglementFacture: () =>
      import('@/views/component/Facture/PopoverReglementFacture.vue')
  },
  mounted() {
    this.getAllTypesReglement();
    this.getAllConditionsPaiement();
  }
};
</script>

<style lang="scss">
.tab-text-bold {
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
}
</style>
