import { render, staticRenderFns } from "./updateReglementModal.vue?vue&type=template&id=84b985fa"
import script from "./updateReglementModal.vue?vue&type=script&lang=js"
export * from "./updateReglementModal.vue?vue&type=script&lang=js"
import style0 from "./updateReglementModal.vue?vue&type=style&index=0&id=84b985fa&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports